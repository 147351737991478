#parallaxintro .view {
  min-height: 100vh !important;
  height: unset !important;
}

#sectionpage .view {
  overflow: visible;
}

footer.page-footer .footer-copyright {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: blue;
  background-color: white;
  z-index:9999999;
}
